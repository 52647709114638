import React, { useContext } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Image from 'gatsby-image'
import { StaticImage } from "gatsby-plugin-image"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clienteAxios from '../config/Axios'
import Swal from 'sweetalert2';
import { AppContext } from '../context/AppContext';
import emailjs from 'emailjs-com';

const Container = styled.section`
    padding:6rem 0;
    max-width:1300px;
    width:95%;
    margin:0 auto;
    /* margin-top:20vh; */
    @media(max-width:768px){
        max-width:720px;
    }
    @media(max-width:992px){
        max-width:960px;
    }
    @media(max-width:1200px){
        max-width:1140px;
    }
`

const Form = styled.form`
display:block;
label{
    display:inline-block;
    padding-bottom:1.5rem;
    width:100%;
    color:#686969;
    font-weight:700;
}
input{
    margin-top:1rem;
    display: block;
    width:100%;
    padding: 2rem;
    border: 1px;
    border-radius: 2rem;
    color:#686969;
    :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(104, 105, 105,0.1);
        transition: box-shadow .15s ease-in-out;
    }
}
input[type=date]{
    -webkit-appearance: textfield;
}
button[type=submit]{
    margin-top:1rem;
    display: block;
    width:80%;
    padding: 2rem;
    border: 1px;
    border-radius: 2rem;
    background-color:#FFBD86;
    color: #6E5139;
    text-transform:uppercase;
    font-weight:700;
    -webkit-appearance: none;
    transition: width .6s ease-in-out;
    transition: max-width .6s ease-in-out;
    @media(min-width:768px){
        max-width:40%;
    transition: max-width .6s ease-in-out;

    }
    :hover{
        cursor:pointer;
        background-color:#FFAF6D;
        transition: background-color .15s ease-in-out;
        width:100%;
        transition: width .6s ease-in-out;
        @media(min-width:768px){
        transition: max-width .6s ease-in-out;
        max-width:50%;
    }
    }
    :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem #6E5139;
        transition: box-shadow .15s ease-in-out;
        background-color:#FFAF6D;
    }
}

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
`

const AgendAppointment = () => {
    const Windows = typeof window !== 'undefined' ? window : '';

    const { setFormNameFn } = useContext(AppContext);

    //     const { image, allStrapiHomePage } = useStaticQuery(graphql`
    // {
    //     image: file(relativePath: { eq:"patient.png" } ) {
    //         sharp:childImageSharp {
    //           fixed {
    //             ...GatsbyImageSharpFixed
    //           }
    //         }
    //       },
    //       allStrapiHomePage {
    //         nodes {
    //             descripcionContacto
    //             subtituloContacto
    //             tituloContacto
    //             tituloDescripcionContacto

    //         }
    //   }
    // }
    // `)

    //     const { tituloContacto, subtituloContacto, descripcionContacto, tituloDescripcionContacto } = allStrapiHomePage.nodes[0]

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            // date: '',
            service: ''
        },
        validationSchema: Yup.object({

            name: Yup
                .string()
                .required('El nombre es requerido'),

            phone: Yup
                .number()
                .required('El número de teléfono es requerido')
                .positive('No se aceptan números negativos')
                .integer('El número de teléfono debe ser un numero entero')
                .required('El número de teléfono es requerido'),

            email: Yup
                .string()
                .email('El email debe ser valido')
                .required('El email es requerido'),

            // date: Yup
            //     .date('La fecha debe ser valida')
            //     .required('La fecha es requerida'),

            service: Yup
                .string()
                .required('El servicio es requerido'),

        }),
        onSubmit: async valores => {
            const data = {
                nombre: valores.name,
                telefono: valores.phone,
                email: valores.email,
                fechaDeCita: new Date(),
                servicioSolicitado: valores.service,
            }
            Swal.fire({
                didOpen: () => {
                    Swal.showLoading()
                }
            })


            var templateParams = {
                para_nombre: 'Plussalud',
                nombre: `${data.nombre}`,
                email: `${data.email}`,
                telefono: `${data.telefono}`,
                servicio: `${data.servicioSolicitado}`
            };

            emailjs.send('service_whprh1v', 'template_s3b6ldc', templateParams, 'AOldDUYPAgv-Qdm7r')
                .then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                    console.log(data.nombre)

                    setFormNameFn(data.nombre, navigate)

                    Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Mensaje enviado',
                    })

                }, function (error) {
                    console.log('FAILED...', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Ha ocurrido un error!',
                    })
                });


            // clienteAxios.post('/contact-forms', data).then(res => {
            //     Swal.close()
            //     setFormNameFn(valores.name, navigate)
            // }).catch(err => {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Lo siento.',
            //         text: 'Error de conexión'
            //     })
            // })

            // 0D6C366CC2801EF16F97F1B1BE2F7141241ABFFF3FE90C55902803534DA222345FE55F9A979E86A45C62763DD3CDCF6D
            //2525
            //072865431EB5F8105B750342B793551877A7


            // Windows.Email.send({
            //     Host: "smtp.elasticemail.com",
            //     Username: "plus.salud.ltda@gmail.com",
            //     Password: "3803A9017928797679400A3CF7830C066FEE",
            //     To: 'plus.salud.ltda@gmail.com',
            //     From: "jhonder.technical@gamail.com",

            // Username: "rayodent.temuco.2@gmail.com",
            // Password: "072865431EB5F8105B750342B793551877A7",
            // To: 'rayodent.temuco@gmail.com',
            // From: "rayodent.temuco.2@gmail.com",

            // Subject: "This is the subject",
            // SecureToken: "3011a638-ec54-45e7-885f-a24f8676a647",
            // To: 'jhonder.technical@gmail.com',
            // From: "rayodent.temuco@gmail.com",


            // plus.salud.ltda@gmail.com
            // 3803A9017928797679400A3CF7830C066FEE
            // smtp.elasticemail.com
            // 2525

            //     Subject: "** Reserva de cita Rayodent **",
            //     Body:
            //         `
            //             Nombre: ${data.nombre}\n \n
            //             Email: ${data.email}\n \n
            //             Telefono: ${data.telefono}\n \n
            //             Fecha de cita: ${data.fechaDeCita}\n \n
            //             Servicio Solicitado: ${data.servicioSolicitado}\n \n
            //         `
            // })
            //     .then(messaje => {

            //         console.log(data.nombre)

            //         setFormNameFn(data.nombre, navigate)

            //         Swal.fire({
            //             icon: 'success',
            //             title: 'Success!',
            //             text: 'Message delivered',
            //         })

            //     })
            //     .catch(err => {
            //         Swal.fire({
            //             icon: 'error',
            //             title: 'Oops...',
            //             text: 'Something went wrong!',
            //         })
            //     })

        }
    })


    return (
        <Container id="Appointment">
            <div>
                <h2
                    css={css`
                    text-align:center;
                    font-size:4rem;
                    text-transform:uppercase;
                    color:#0024B6;
                    @media(min-width:768px){
                        font-size:5rem;
                    }
                    @media(min-width:1200px){
                        font-size:4.5rem;
                        text-align:left;
                    }
                `}>
                    AGENDA UNA CITA CON NOSOTROS TOTALMENTE GRATIS

                </h2>
                <p
                    css={css`
                        font-size: 3rem;
                        color: #164688;
                        @media(min-width:1200px){
                        font-size:2.3rem;
                        text-align:left;
                    }
                    `}>
                    Ingresa tus datos y te llamaremos para reservar
                </p>
            </div>
            <div
                css={css`
                    @media (min-width:1200px){
                        display:grid;
                        align-items:center;
                        grid-template-columns: 1fr 1.5fr;
                        column-gap:3rem;
                    }
                `}
            >
                <div
                    css={css`
                        text-align:center;
                        @media (max-width:1199px){
                            max-width: 750px;
                            margin: auto;
                            padding-bottom: 4rem;
                        }
                        p{
                            text-align:justify;
                        }
                    `}>
                    {/* <Image fixed={imageform}
                        css={css`
                        border-radius: 3rem;
                        max-width:100%;
                        min-width:100%;
                    `} /> */}
                    <StaticImage src="../images/patient.png" alt="img form"
                        css={css`
                            border-radius: 3rem;
                            max-width:100%;
                            min-width:100%;
                        `}
                    />
                    <p
                        css={css`
                        font-weight:700;
                    `}>
                        Centro Radiologico Rayodent
                    </p>
                    <p>
                        Solicita una cita para realizar tus examenes radiograficos con nosotros . Examenes informados entre 24 a 48 horas. Completamente digitales. Arancel preferencial pacientes fonasa y fuerzas armadas .
                    </p>
                </div>
                <div
                    css={css`
                        background-color:#F3F4F5;
                        clip-path: polygon(40% 0%, 50% 10%, 60% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%);
                        border-radius:3rem;
                        padding:15% 3rem;
                    `}>
                    <Form onSubmit={formik.handleSubmit}>
                        <label>
                            Nombre
                            {formik.touched.name && formik.errors.name ? (
                                <>
                                    <span style={{ color: 'red' }}> ({formik.errors.name})</span>
                                </>
                            ) : null}
                            <input
                                name="name"
                                type="text"
                                id="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                        </label>
                        <label>
                            Teléfono
                            {formik.touched.phone && formik.errors.phone ? (
                                <>
                                    <span style={{ color: 'red' }}> ({formik.errors.phone})</span>
                                </>
                            ) : null}
                            <input
                                name="phone"
                                type="number"
                                id="phone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                        </label>
                        <label>
                            Email
                            {formik.touched.email && formik.errors.email ? (
                                <>
                                    <span style={{ color: 'red' }}> ({formik.errors.email})</span>
                                </>
                            ) : null}
                            <input
                                name="email"
                                type="email"
                                id="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                        </label>
                        <label>
                            Servicio
                            {formik.touched.service && formik.errors.service ? (
                                <>
                                    <span style={{ color: 'red' }}> (Requerido)</span>
                                </>
                            ) : null}
                            <input
                                name="service"
                                type="text"
                                id="service"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.service}
                            />
                        </label>
                        <button type="submit">Enviar</button>
                    </Form>
                </div>
            </div>
        </Container>
    );
}

export default AgendAppointment;